import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/SEO"
import Wave from "../components/wave"
import Footer from "../components/footer"

const WorksPage = ({
  data,
  pageContext: { locale, alternateLanguages },
  location,
}) => {
  const worksPrismic = data.prismicJobpage
  return (
    <>
      <Seo
        title={worksPrismic.data.seo_title}
        desc={worksPrismic.data.seo_description}
        pathname={location.pathname}
        locale={locale}
        alternateLanguages={alternateLanguages}
      />
      <Wave title={worksPrismic.data.title.text} />
      <section className=" relative z-10 px-10 mx-auto mb-20  max-w-3xl flex flex-col md:flex md:items-start md:w-3/5   ">
        <h2 className="text-xl font-sans font-bold text-comet text-center m-auto mb-10">
          {worksPrismic.data.subtitle.text}
        </h2>
        {worksPrismic.data.jobs.map(job => {
          return (
            <div className="mb-14 pb-8 rounded-2xl shadow">
              <GatsbyImage
                image={getImage(job.image)}
                alt={job.image.alt}
                className="mx-0 mb-2 md:mb-8 "
              />
              <div className="flex flex-col px-3 md:flex-row md:mx-10 md:px-0">
                <div className=" md:ml-5 md:order-2">
                  <h2 className="text-2xl  font-sans font-bold text-comet">
                    {job.job_title.text}
                  </h2>

                  <div
                    className=" mt-1 text-base font-normal  text-gray-500 font-serif z-10 relative"
                    dangerouslySetInnerHTML={{
                      __html: job.job_description.html,
                    }}
                  />
                </div>
                <div className="flex flex-row flex-wrap mt-5 items-start md:flex-col  md:order-1 md:mt-0">
                  {job.job_tags.split(",").map(tag => (
                    <div className="px-2 py-1 mb-2 mr-2 rounded-md font-sans text-sm font-bold uppercase  bg-polar text-gray-500 ">
                      {tag}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )
        })}
      </section>
      <Footer posts={data.allPrismicBlogpost} />
    </>
  )
}

export default WorksPage

WorksPage.propTypes = {
  data: PropTypes.shape({
    prismicJobpage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query WorksQuery($locale: String!) {
    prismicJobpage(lang: { eq: $locale }) {
      alternate_languages {
        uid
      }
      uid
      data {
        title {
          html
          text
        }
        subtitle {
          html
          text
        }
        jobs {
          job_title {
            html
            text
          }
          job_description {
            html
            text
          }
          job_tags
          image {
            gatsbyImageData(width: 700)
          }
        }
      }
    }

    allPrismicBlogpost(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          lang
          data {
            title {
              text
              html
            }
            body {
              ... on PrismicBlogpostDataBodyContent {
                id
                slice_type
                slice_label
                primary {
                  richtext {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
